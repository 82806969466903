import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'

const CollectionSlider = ({
  desktopView,
  tabletView
}) => ({
  swiper: null,

  init () {
    this.intiSwiper()
  },

  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.collections, {
      slidesPerView: 1.14,
      modules: [Pagination],
      spaceBetween: 4,
      // pagination
      pagination: {
        el: '.swiper-pagination'
      },
      breakpoints: {
        768: {
          slidesPerView: tabletView
        },
        1024: {
          slidesPerView: desktopView
        }
      }
    })
  }
})

export default CollectionSlider
