import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

import 'swiper/css/pagination'

const ProductInfoCarousel = () => ({
  swiper: null,

  init () {
    this.swiper = new Swiper(this.$refs.swiper, {
      autoHeight: true,
      modules: [Pagination],
      pagination: {
        enabled: true,
        el: this.$refs.swiperPagination
      },
      spaceBetween: 16
    })
    console.log('s')
  },

  toggleVideoPlayback (videoId) {
    // Find the video element with the matching data-video attribute
    const videoElement = document.querySelector(`video[data-video="${videoId}"]`) as HTMLVideoElement

    console.log(videoId)

    if (videoElement) {
      // Toggle play/pause
      if (videoElement.paused) {
        videoElement.play()
      } else {
        videoElement.pause()
      }

      // Add or remove the 'video-playing' class from the parent wrapper
      const videoWrapper = videoElement.closest('.product-info-carousel__video-wrapper')
      if (videoWrapper) {
        videoWrapper.classList.toggle('video-playing', !videoElement.paused)
      }
    }
  }
})

export default ProductInfoCarousel
