import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/autoplay'

const MarqueeText = () => ({
  swiper: null,

  init () {
    this.intiSwiper()
  },

  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.slider, {
      autoplay: {
        delay: 1
      },
      centeredSlides: true,
      loop: true,
      modules: [Autoplay],
      speed: 8000,
      slidesPerView: 'auto',
      spaceBetween: 10
    })
  }
})

export default MarqueeText
