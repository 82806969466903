import 'vite/modulepreload-polyfill'
// uncomment if needed
import Alpine from 'alpinejs'

import CollectionSlider from '@scripts/sections/collection-slider'
import FeaturedProductSlider from '@scripts/sections/featured-product-slider'
import MarqueeText from '@scripts/sections/marquee-text'
import ProductInfoCarousel from '@scripts/sections/product-information-carousel'

document.addEventListener('alpine:init', () => {
  // store

  // sections
  Alpine.data('collectionSlider', CollectionSlider)
  Alpine.data('featuredProductSlider', FeaturedProductSlider)
  Alpine.data('marqueeText', MarqueeText)
  Alpine.data('productInfoCarousel', ProductInfoCarousel)
})

Alpine.start()
